import { ChangeEvent, FC, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { useSnackbar } from 'notistack';
import get from 'lodash/get';
import useRouter from 'next/router';
import Head from 'next/head';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';

import ALink from 'components/atoms/ALink';
import MLoginPanelSlider from 'components/molecules/MLoginPanelSlider';
import MTextField from 'components/molecules/MTextField';
import MPasswordField from 'components/molecules/MPasswordField';
import MSocialLoginButton from 'components/molecules/MSocialLoginButton';
import WithAuthSync from 'middleware/authMiddleware';
import { getProfile, login } from 'redux-saga/actions';
import { validateEmailCommon } from 'helpers';
import { AppState } from 'redux-saga/interfaces';
import MLanguageSelector from 'components/molecules/MLanguageSelector';

interface State {
  email: string;
  password: string;
  isShowPassword: boolean;
  isEmailError: boolean;
  isPasswordError: boolean;
  isValidated: boolean;
}

const Login: FC<AppState> = () => {
  const { t } = useTranslation('login');
  const { t: commonT } = useTranslation('common');
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState<State>({
    email: '',
    password: '',
    isShowPassword: false,
    isEmailError: false,
    isPasswordError: false,
    isValidated: false,
  });

  const handleChange = (prop: keyof State) => (event: ChangeEvent<HTMLInputElement>) => {
    switch (prop) {
      case 'email':
        setValues({ ...values, [prop]: event.target.value, isEmailError: false });
        break;
      case 'password':
        setValues({ ...values, [prop]: event.target.value, isPasswordError: false });
        break;
      default:
        setValues({ ...values, [prop]: event.target.value });
    }
  };

  const validate = () => {
    if (!values.email || !values.email.trim() || !values.password) {
      setValues({
        ...values,
        isValidated: false,
        isEmailError: !(values.email && values.email.trim()),
        isPasswordError: !values.password,
      });
      return false;
    }
    setValues({ ...values, isValidated: true });
    return true;
  };

  const submitLogin = (event) => {
    event.preventDefault();
    if (validate()) {
      const callbacks = {
        onFailure: (error) => {
          const message = get(error, ['non_field_errors', 0]);
          if (message) enqueueSnackbar(message, { variant: 'error' });
          setIsLoading(false);
        },
        onBegin: () => setIsLoading(true),
        onSuccess: ({ user }) => {
          handleNextPage(user.onboarding_urbancv);
        },
      };
      dispatch(login(values, callbacks));
    }
  };

  const handleNextPage = (isOnBoarding: boolean) => {
    const callbacks = {
      onFinish: () => setIsLoading(false),
      onSuccess: () => {
        const nextPage = !isOnBoarding ? '/onboarding' : '/my-cvs';
        useRouter.push(nextPage);
      },
    };
    dispatch(getProfile({}, callbacks));
  };

  return (
    <div>
      <Head>
        <title>Urban CV</title>
        <link rel="icon" href="/favicon.ico" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap"
          rel="stylesheet"
        />
      </Head>

      <Box display="flex" alignItems="center" height="100vh" px={12} mx="auto" maxWidth={1200}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box
              maxWidth="416px"
              width="100%"
              height={{ xs: 'auto', md: '90vh' }}
              mx={{ xs: 'auto', md: 'unset' }}
              maxHeight="784px"
            >
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <ALink href="/">
                  <Image src="/assets/logo.png" alt="Urban CV" width={150} height={30} className="u-pointer" />
                </ALink>
                <MLanguageSelector />
              </Box>
              <Box mt={25}>
                <h1>{t('login')}</h1>
              </Box>
              <Box component="form" onSubmit={submitLogin}>
                <Box mt={27}>
                  <MTextField
                    id="email"
                    title={t('email')}
                    error={values.isEmailError}
                    value={values.email}
                    disabled={isLoading}
                    onChange={handleChange('email')}
                    placeholder={t('enterEmailAddress')}
                    helperText={
                      values.isEmailError
                        ? !values.email.trim()
                          ? commonT('helperText.fieldRequired')
                          : !validateEmailCommon(values.email.trim())
                          ? commonT('helperText.invalidEmail')
                          : commonT('helperText.emailAddressNotFound')
                        : ''
                    }
                  />
                </Box>
                <Box mt={8}>
                  <MPasswordField
                    id="password"
                    title={t('password')}
                    error={values.isPasswordError}
                    value={values.password}
                    disabled={isLoading}
                    onChange={handleChange('password')}
                    placeholder={t('enterPassword')}
                    helperText={
                      values.isPasswordError
                        ? values.email && values.email.trim()
                          ? commonT('helperText.passwordWrong')
                          : commonT('helperText.fieldRequired')
                        : ''
                    }
                  />
                </Box>
                <Box display="flex" justifyContent="flex-end" alignItems="center" mb={10}>
                  <ALink href="/forgot-password">
                    <Box fontSize={12} className="u-link">
                      {t('forgotPassword')}?
                    </Box>
                  </ALink>
                </Box>
                <Button
                  variant="contained"
                  fullWidth
                  disableElevation
                  type="submit"
                  className="c-primary-button"
                  color="primary"
                  disabled={isLoading}
                >
                  {!isLoading ? t('login') : <CircularProgress size={24} />}
                </Button>
              </Box>
              <Box mt={8} textAlign="center" className="o-caption1">
                <Box component="span" mr={2}>
                  {t('dontHaveAnAccount')}?
                </Box>
                <ALink href="/register">
                  <Box component="span" className="u-link" fontWeight="700">
                    {t('signUp')}
                  </Box>
                </ALink>
              </Box>
              <Box mt={8} display="flex" alignItems="center">
                <Box width="100%">
                  <Divider />
                </Box>
                <Box mx={8} width={40} textAlign="center" whiteSpace="nowrap">
                  {t('or')}
                </Box>
                <Box width="100%">
                  <Divider />
                </Box>
              </Box>
              <Box mt={6}>
                <MSocialLoginButton />
              </Box>
            </Box>
          </Grid>
          <Hidden smDown>
            <Grid item md={6} className="c-login-panel-right">
              <Image
                src="/assets/svg/panel_right_vector.svg"
                alt="CV"
                layout="fill"
                objectFit="contain"
                objectPosition="right top"
                className="c-login-panel-right__img"
              />
              <MLoginPanelSlider />
            </Grid>
          </Hidden>
        </Grid>
      </Box>
    </div>
  );
};

export default connect((state) => state)(WithAuthSync(Login));
